import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import logo from '../assets/tafs.png';
import { fetchSnippets, setInitial } from '../store/snippet-actions';
import { useDispatch } from 'react-redux';

function Searchbar() {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');

  async function handleSubmit(e) {
    document.activeElement.blur();
    e.preventDefault();
    if (input.length > 0 && input) {
      dispatch(fetchSnippets(input));
    }
  }

  function handleTextareaChange(e) {
    setInput(e.target.value);
  }

  return (
    <div className='bg-white border-b'>
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div className='flex h-16 justify-between'>
          <div className='flex'>
            <div className='flex flex-shrink-0 items-center'>
              <img
                className='block md:h-6 h-4 w-auto cursor-pointer'
                src={logo}
                alt='Your Company'
                onClick={() => {
                  dispatch(setInitial());
                }}
              />
            </div>
          </div>
          <div className='min-w-0 flex-1 md:px-8 xl:col-span-6'>
            <div className='flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:max-w-none'>
              <div className='w-full'>
                <label htmlFor='search' className='sr-only'>
                  Search
                </label>
                <div className='relative'>
                  <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <MagnifyingGlassIcon
                      className='h-5 w-5 text-gray-400'
                      aria-hidden='true'
                    />
                  </div>
                  <form onSubmit={handleSubmit}>
                    <input
                      id='search'
                      name='search'
                      className='block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-100 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm'
                      placeholder='Search'
                      type='search'
                      value={input}
                      onChange={handleTextareaChange}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Searchbar;
