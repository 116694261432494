import { MicrophoneIcon } from '@heroicons/react/20/solid';
import { Pause, Play } from './Buttons';
import { useDispatch } from 'react-redux';
import {
  setActive,
  setDuration,
  setPaused,
  setPlaying,
  setSeek,
} from '../store/snippet-actions';
import { formatDuration } from '../helpers/time';

function Snippet({ snippet, active, playing }) {
  const dispatch = useDispatch();

  return (
    <div className='px-4 py-2 sm:px-6'>
      <div className='flex items-center justify-between'>
        <div className='flex flex-col justify-between min-w-0'>
          <h3 className='py-4 sm:text-lg text-md sm:max-w-md md:max-w-xl lg:max-w-3xl'>
            <mark className='mark-rounded mark-blue'>{snippet.text}</mark>
          </h3>
          <div className='flex items-center flex-shrink'>
            <MicrophoneIcon className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' />
            <p className='text-xs sm:text-sm text-gray-500'>
              {snippet.episode_name}
            </p>
          </div>
        </div>
        <div className='flex flex-col items-center pl-6'>
          {playing ? (
            <Pause
              handleClick={() => {
                dispatch(setPaused());
              }}
            />
          ) : (
            <Play
              handleClick={() => {
                dispatch(setActive(snippet));
                dispatch(setSeek(snippet.start / 1000));
                dispatch(setPlaying());
              }}
            />
          )}
          <p className='flex-shrink-1 text-xs text-gray-500'>
            {formatDuration(snippet.start / 1000)}
          </p>
        </div>
      </div>
    </div>
  );
}

function SnippetList({ snippets, audioState }) {
  return (
    <ul className='space-y-5'>
      {snippets.map((snippet, index) => (
        <li
          key={index}
          className='overflow-hidden border-gray-500 bg-white px-2 py-2'>
          <Snippet
            snippet={snippet}
            active={snippet.id === audioState.active?.id}
            playing={
              snippet.id === audioState.active?.id ? audioState.playing : false
            }
          />
        </li>
      ))}
    </ul>
  );
}

export default SnippetList;
