import Layout from './components/Layout';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { snippets, audioState } from './store/snippets-reducer';

const rootReducer = combineReducers({
  snippets: snippets,
  audioState: audioState,
});
const store = configureStore({
  reducer: rootReducer,
});

function App() {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
}

export default App;
