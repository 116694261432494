import AudioPlayer from './AudioPlayer';
import Searchbar from './Searchbar';
import SnippetList from './Snippet';
import { useSelector, useDispatch } from 'react-redux';
import { Back, Next } from './Buttons';
import { fetchSnippets } from '../store/snippet-actions';
import { ReactComponent as LoadingIcon } from '../assets/bars.svg';

function Layout() {
  const { snippets, loading, total, page, pages, query } = useSelector(
    (state) => state.snippets
  );
  const audioState = useSelector((state) => state.audioState);
  const dispatch = useDispatch();

  if (loading) window.scrollTo(0, 0);

  return (
    <div className='min-h-full'>
      <Searchbar />
      {loading ? (
        <div className='flex m-40 items-center justify-center'>
          <LoadingIcon className='h-12 text-blue-500' />
        </div>
      ) : query ? (
        total > 0 ? (
          <div className={`pt-2  ${audioState.active ? `pb-safe-40` : `pb-4`}`}>
            <div className='mx-auto max-w-7xl px-3 sm:px-6 lg:px-8'>
              <p className='p-2 text-sm text-gray-500'>{total} results</p>
              <SnippetList snippets={snippets} audioState={audioState} />
            </div>
            <div className='flex gap-2 mt-4 items-center justify-center'>
              <Back
                disabled={page === 1}
                handleClick={() => {
                  dispatch(fetchSnippets(query, page - 1));
                }}></Back>
              <p className='p-2 text-sm text-gray-500'>
                {page} / {pages}
              </p>
              <Next
                disabled={page === pages}
                handleClick={() => {
                  dispatch(fetchSnippets(query, page + 1));
                }}></Next>
            </div>
          </div>
        ) : (
          <div className='flex mt-14 items-center justify-center'>
            <p className='p-2 text-center text-md max-w-md md:max-w-xl text-gray-500'>
              No results.
            </p>
          </div>
        )
      ) : (
        <div className='flex mt-14 items-center justify-center'>
          <p className='p-2 text-center text-sm sm:text-base max-w-xs sm:max-w-md md:max-w-xl text-gray-500'>
            Search for any name, place, thing, or text phrase mentioned on The
            Adam Friedland Show and play the podcast from that moment.
          </p>
        </div>
      )}

      {audioState.active ? (
        <AudioPlayer audioState={audioState} snippet={audioState.active} />
      ) : null}
    </div>
  );
}

export default Layout;
