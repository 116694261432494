import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/solid';

import { ReactComponent as ForwardIcon } from '../assets/forward.svg';
import { ReactComponent as RewindIcon } from '../assets/rewind.svg';

function Play(props) {
  const { handleClick } = props;

  return (
    <button className='player__button' onClick={() => handleClick()}>
      <PlayCircleIcon className='h-12 text-blue-600 hover:text-blue-800' />
    </button>
  );
}

function Pause(props) {
  const { handleClick } = props;

  return (
    <button className='player__button' onClick={() => handleClick()}>
      <PauseCircleIcon className='h-12 text-blue-600 hover:text-blue-800' />
    </button>
  );
}

function Forward(props) {
  const { handleClick } = props;

  return (
    <button className='player__button' onClick={() => handleClick()}>
      <ForwardIcon className='h-7 text-blue-600 hover:text-blue-800' />
    </button>
  );
}

function Rewind(props) {
  const { handleClick } = props;

  return (
    <button className='player__button' onClick={() => handleClick()}>
      <RewindIcon className='h-7 text-blue-600 hover:text-blue-800' />
    </button>
  );
}

function Next(props) {
  const { handleClick, disabled = false } = props;
  return (
    <button
      disabled={disabled}
      className='player__button'
      onClick={() => handleClick()}>
      <ChevronRightIcon
        className={`h-9 ${
          disabled ? `text-gray-400` : `text-blue-600 hover:text-blue-800`
        }`}
      />
    </button>
  );
}
function Back(props) {
  const { handleClick, disabled = false } = props;

  return (
    <button
      disabled={disabled}
      className='player__button'
      onClick={() => handleClick()}>
      <ChevronLeftIcon
        className={`h-9 ${
          disabled ? `text-gray-400` : `text-blue-600 hover:text-blue-800`
        }`}
      />
    </button>
  );
}

export { Play, Pause, Forward, Rewind, Next, Back };
