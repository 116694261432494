import {
  SET_ACTIVE,
  SET_DURATION,
  SET_INACTIVE,
  SET_INITIAL,
  SET_LOADED,
  SET_LOADING,
  SET_PAUSED,
  SET_PLAYING,
  SET_QUERY,
  SET_SEEK,
  SET_SNIPPETS,
  SET_TIME,
} from './snippet-actions';

const snippets = (
  state = {
    snippets: [],
    loading: false,
    total: 0,
    page: 0,
    pages: 0,
    size: 0,
    query: '',
  },
  action
) => {
  switch (action.type) {
    case SET_SNIPPETS:
      return {
        ...state,
        snippets: action.payload.snippets,
        total: action.payload.total,
        page: action.payload.page,
        pages: action.payload.pages,
        size: action.payload.size,
      };
    case SET_QUERY:
      return { ...state, query: action.payload.query };
    case SET_LOADING:
      return { ...state, loading: true };
    case SET_LOADED:
      return { ...state, loading: false };
    case SET_INITIAL:
      return {
        snippets: [],
        loading: false,
        total: 0,
        page: 0,
        pages: 0,
        size: 0,
        query: '',
      };
    default:
      return state;
  }
};

const audioState = (
  state = {
    active: null,
    playing: false,
    duration: 0,
    time: 0,
    seek: 0,
  },
  action
) => {
  switch (action.type) {
    case SET_ACTIVE:
      return { ...state, active: action.payload.active };
    case SET_INACTIVE:
      return { ...state, active: null };
    case SET_PLAYING:
      return { ...state, playing: true };
    case SET_PAUSED:
      return { ...state, playing: false };
    case SET_DURATION:
      return { ...state, duration: action.payload.duration };
    case SET_TIME:
      return { ...state, time: action.payload.time };
    case SET_SEEK:
      return { ...state, seek: action.payload.seek };

    case SET_INITIAL:
      return {
        active: null,
        playing: false,
        duration: 0,
        time: 0,
        seek: 0,
      };
    default:
      return state;
  }
};

export { snippets, audioState };
