import { Forward, Pause, Play, Rewind } from './Buttons';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  setDuration,
  setPaused,
  setPlaying,
  setSeek,
  setTime,
} from '../store/snippet-actions';
import { formatDuration } from '../helpers/time';

function AudioPlayer({ audioState, snippet }) {
  const audio = useMemo(() => new Audio(), []);
  const dispatch = useDispatch();

  useEffect(() => {
    const setAudioData = (event) => {
      dispatch(setDuration(event.target.duration));
    };

    const setAudioTime = (event) => {
      dispatch(setTime(event.target.currentTime));
    };

    audio.addEventListener('loadeddata', setAudioData);
    audio.addEventListener('timeupdate', setAudioTime);

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  }, [audio, dispatch]);

  audioState.playing ? audio.play() : audio.pause();

  useEffect(() => {
    if (audioState.active) {
      audio.src = snippet.audio_url;
      audio.load();
    }
  }, [audioState.active, snippet, audio, dispatch]);

  useEffect(() => {
    audio.currentTime = audioState.seek;
  }, [audioState.seek, audio, dispatch]);

  return (
    <div className='fixed bottom-0 z-40 border-t border-b pt-4 pb-safe-4 px-2 bg-white w-screen'>
      <div className='flex flex-row justify-center mx-2 gap-4'>
        <img
          src={snippet.icon}
          className='flex-grow-0 h-20 sm:h-28 self-center'
          alt={snippet.podcast_name}
        />
        <div className='flex flex-grow flex-col items-center max-w-7xl min-w-0'>
          <p className='text-sm text-gray-500  max-w-full truncate'>
            {snippet.episode_name}
          </p>
          <div className='flex flex-col w-11/12'>
            <input
              type='range'
              step='any'
              value={audioState.time}
              min='0'
              max={audioState.duration}
              onInput={(event) => dispatch(setSeek(event.target.value))}
              className='rounded-lg h-2 bg-gray-200  mt-4'
              style={{
                background: `linear-gradient(to right, blue ${
                  (audioState.time / audioState.duration) * 100
                }%, white 0)`,
              }}
            />
            <div className='mt-1 mx-1 flex justify-between'>
              <p className='text-xs text-gray-500'>
                {formatDuration(audioState.time)}
              </p>
              <p className='text-xs text-gray-500'>
                {formatDuration(audioState.duration)}
              </p>
            </div>
          </div>
          <div className='flex flex-row gap-2'>
            <Rewind
              className=''
              handleClick={() => {
                dispatch(setSeek(audioState.time - 15));
              }}
            />
            {audioState.playing ? (
              <Pause
                handleClick={() => {
                  dispatch(setPaused());
                }}
              />
            ) : (
              <Play
                handleClick={() => {
                  dispatch(setPlaying());
                }}
              />
            )}
            <Forward
              handleClick={() => {
                dispatch(setSeek(audioState.time + 30));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AudioPlayer;
